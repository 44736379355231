import { getOrderStatus } from './ServiceQwickcilver';

export const getQwickcilverOrderStatus = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'LOADING_ORDER_STATUS_QWICKCILVER',
      });
      const res = await getOrderStatus(params);
      console.log('🚀 ~ return ~ res:', res);
      const { code, data } = res;

      if (code === 200) {
        dispatch({
          type: 'SUCCESS_ORDER_STATUS_QWICKCILVER',
          data: data,
        });
      } else {
        dispatch({
          type: 'ERROR_ORDER_STATUS_QWICKCILVER',
        });
      }
    } catch (error) {
      dispatch({
        type: 'ERROR_ORDER_STATUS_QWICKCILVER',
      });
    }
  };
};

const defaultState = {
  loading: false,
  status: 'default',
  data: [],
  id: '',
  filter: {
    supplier_id: '',
  },
  pagination: {
    limit: 10,
    page: 1,
    total: 0,
  },
};

let response = {};
const OrderHistoryLib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'SUCCESS_QWICKSILVER_ORDER_HISTORY':
      response = {
        ...state,
        status: 'success',
        data: action.data || [],
        pagination: action.pagination || { limit: 10, page: 1, total: 0 },
        filter: action.filter || {},
      };
      break;
    case 'LOADING_QWICKSILVER_ORDER_HISTORY':
      response = {
        ...state,
        filter: action.filter || {},
        status: 'loading',
      };
      break;
    case 'ERROR_QWICKSILVER_ORDER_HISTORY':
      response = {
        ...state,
        status: 'error',
      };
      break;
    default:
      response = {
        ...state,
      };
      break;
  }

  return response;
};

export default OrderHistoryLib;

import { getOrderDetail } from './ServiceQwickcilver';

export const getOrderDetailQwickCilver = (params) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'LOADING_DETAILORDER_QUICKSILVER', data: {} });

      const res = await getOrderDetail(params);
      const { code, data } = res;
      if (code === 200) {
        dispatch({ type: 'SUCCESS_DETAILORDER_QUICKSILVER', data: data });
      } else {
        dispatch({ type: 'ERROR_DETAILORDER_QUICKSILVER', data: {} });
      }
    } catch (error) {
      dispatch({ type: 'ERROR_DETAILORDER_QUICKSILVER', data: {} });
    }
  };
};

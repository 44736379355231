const defaultState = {
  loading: false,
  status: 'default',
  data: [],
};

let response = {};
const SupplierAct = (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'SUCCESS_SUPPLIER':
      response = {
        ...state,
        status: 'success',
        data: action.data || [],
        filter: action.filter || [],
      };
      break;
    case 'LOADING_SUPPLIER':
      response = {
        ...state,
        status: 'loading',
      };
      break;
    case 'ERROR_SUPPLIER':
      response = {
        ...state,
        status: 'error',
      };
      break;
    default:
      response = {
        ...state,
      };
      break;
  }

  return response;
};

export default SupplierAct;

import { getOrderHistory } from './ServiceQwickcilver';

export const getQwickSilverOrderHistory = (params, filter) => {
  return async (dispatch) => {
    const { supplier_id } = params;
    console.log('🚀 ~ return ~ data:', filter);
    dispatch({
      type: 'LOADING_QWICKSILVER_ORDER_HISTORY',
      filter: params,
    });
    try {
      const rest = await getOrderHistory(supplier_id, filter);
      const { code, data, meta } = rest;

      if (code === 200) {
        dispatch({
          type: 'SUCCESS_QWICKSILVER_ORDER_HISTORY',
          data: data,
          pagination: meta,
          filter: params,
        });
      } else {
        dispatch({
          type: 'ERROR_QWICKSILVER_ORDER_HISTORY',
          data: [],
          pagination: {},
          filter: params,
        });
      }
      console.log('🚀 ~ return ~ rest:', rest);
    } catch (error) {
      dispatch({ type: 'ERROR_QWICKSILVER_ORDER_HISTORY', data: [], pagination: {} });
    }
  };
};

const defaultState = {
  status: 'default',
  data: {},
  loading: false,
};

let response = {};

const OrderDetailLib = (state = defaultState, actions = {}) => {
  switch (actions.type) {
    case 'SUCCESS_DETAILORDER_QUICKSILVER':
      response = {
        ...state,
        status: 'success',
        data: actions.data || {},
      };
      break;
    case 'LOADING_DETAILORDER_QUICKSILVER':
      response = {
        ...state,
        status: 'loading',
      };
      break;
    case 'ERROR_DETAILORDER_QUICKSILVER':
      response = {
        ...state,
        status: 'error',
      };
      break;

    default:
      response = {
        ...state,
      };
      break;
  }

  return response;
};

export default OrderDetailLib;

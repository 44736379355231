import QueryString from 'qs';
import { CONFIG_ } from '../../../config/Config';
import { CONNECTION } from '../../../config/Connection';

export const getOrderHistory = async (supplier_id = '', filter = { limit: 1, page: 1 }) => {
  const query = QueryString.stringify(filter, { encode: false });
  const config = CONFIG_({
    url: `voucher/qwikcilver-order-list/${supplier_id}?${query}`,
    method: 'GET',
  });

  try {
    const res = await CONNECTION(config);
    return res;
  } catch (e) {
    return null;
  }
};

export const getOrderStatus = async ({ ref_id, supplier_id }) => {
  const config = CONFIG_({
    url: `voucher/qwikcilver-order-status/${supplier_id}/${ref_id}`,
    method: 'GET',
  });

  try {
    const res = await CONNECTION(config);
    console.log('🚀 ~ getOrderStatus ~ res:', res);
    return res;
  } catch (e) {
    return null;
  }
};

export const getOrderDetail = async ({ order_id, supplier_id }) => {
  const config = CONFIG_({
    url: `voucher/qwikcilver-order-detail/${supplier_id}/${order_id}`,
    method: 'GET',
  });

  try {
    const res = await CONNECTION(config);
    return res;
  } catch (e) {
    return null;
  }
};

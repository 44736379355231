import { CONFIG_ } from '../config/Config';
import { CONNECTION } from '../config/Connection';

const SupplierSvc = process.env.REACT_APP_PATH_SUPPLIER;

export const getAllSuppliers = async () => {
  const config = CONFIG_({
    url: SupplierSvc + 'get/all?pagination[pageSize]=100',
  });
  const response = await CONNECTION(config);
  return response;
};

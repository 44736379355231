import { getAllSuppliers } from '../../../services/SupplierServices';

export const getSupplier = () => {
  return async (dispatch) => {
    dispatch({ type: 'LOADING_SUPPLIER' });

    try {
      const rest = await getAllSuppliers();
      const { code, data } = rest;
      if (code === 200) {
        dispatch({ type: 'SUCCESS_SUPPLIER', data: data });
      } else {
        dispatch({ type: 'ERROR_SUPPLIER' });
      }
    } catch (error) {
      dispatch({ type: 'ERROR_SUPPLIER' });
    }
  };
};
